/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import config from "../config"

function SEO({ description, lang, meta, title, titleDesc, titleKeywords, fbDes, twitDesc, seoLink }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            titleDesc
            titleKeywords
            fbDes
            twitDesc
            seoLink
          }
        }
      }
    `
  )

  // const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `author`,
          content: `Fusemachines Inc`,
        },
        {
          name: `Geography`,
          content: `106 W 32nd St floor 2 New York, NY 10001`,
        },
        {
          name: `Language`,
          content: `English`,
        },
        {
          name: `Copyright`,
          content: `Fuse Classroom`,
        },
        {
          name: `distribution`,
          content: `Global`,
        },
        {
          name: `target`,
          content: `all`,
        },
        {
          name: `reply-to`,
          content: `info@fuse.ai`,
        },
        {
          name: `rating`,
          content: `General`,
        },
        {
          name: `Robots`,
          content: `INDEX,FOLLOW`,
        },
        {
          name: `zipcode`,
          content: `10001`,
        },
        {
          name: `city`,
          content: `New York`,
        },
        {
          name: `country`,
          content: `USA`,
        },
        {
          name: `Cache-Control`,
          content: `no-cache, no-store, must-revalidate`,
        },
        {
          name: `Pragma`,
          content: `no-cache`,
        },
        {
          name: `Expires`,
          content: `0`,
        },
        {
          name: `subject`,
          content: `AI Course`,
        },
        {
          name: `description`,
          content: titleDesc,
        },
        {
          name: `keyword`,
          content: titleKeywords,
        },
        // social
        // facebook
        {
          property: `fb:app_id`,
          content: `227893031220193`,
        },
        {
          property: `og:url`,
          content: config.basePath + seoLink,
        },
        {
          property: `og:title`,
          content: title + ` | Fuse Classroom`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:description`,
          content: fbDes,
        },
        {
          property: `og:image:type`,
          content: `image/jpeg`,
        },
        {
          property: `og:image`,
          content: config.basePath + `images/ogimage.jpg`,
        },
        {
          property: `og:image:width`,
          content: `600`,
        },
        {
          property: `og:image:height`,
          content: `315`,
        },
        // twitter
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:url`,
          content: config.basePath + seoLink,
        },
        {
          name: `twitter:creator`,
          content: "@fuse.ai",
        },
        {
          name: `twitter:title`,
          content: title + ` | Fuse Classroom`,
        },
        {
          name: `twitter:description`,
          content: twitDesc,
        },
        {
          name: `twitter:image`,
          content: config.basePath + `images/ogimage.jpg`,
        },
        {
          name: `twitter:image:alt`,
          content: `AI Course`,
        },
      ].concat(meta)}
    ></Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,

  titleDesc: ``,
  titleKeywords: ``,
  fbDes: ``,
  twitDesc: ``,
  seoLink: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,

  titleDesc: PropTypes.string,
  titleKeywords: PropTypes.string,
  fbDes: PropTypes.string,
  twitDesc: PropTypes.string,
  seoLink: PropTypes.string,
}

export default SEO
